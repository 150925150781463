<template>
  <div class="layout">
    <div class="tab-bar" ref="outer" @scroll.passive="getScroll($event)">
      <div class="img-layout-box" @click="goToUrl(1)">
        <img src="~@/assets/home/shop.png" class="img-size" />
        <span class="lable-text">本地生活</span>
      </div>
      <div class="img-layout-box" @click="goToUrl(7)">
        <img src="~@/assets/seize-img/xsd.png" class="img-size" />
        <span class="lable-text">抢购专区</span>
      </div>
      <div class="img-layout-box" @click="goToUrl(8)">
        <img src="~@/assets/seize-img/pt.png" class="img-size" />
        <span class="lable-text">跑腿</span>
      </div>
      <div class="img-layout-box" @click="goToUrl(2)">
        <img src="~@/assets/home/icon-charity-Bean-Mall.png" class="img-size" />
        <span class="lable-text">公益豆</span>
      </div>
      <div class="img-layout-box" @click="goToUrl(3)">
        <img src="~@/assets/home/icon-Its-franchisees.png" class="img-size" />
        <span class="lable-text">优惠专区</span>
      </div>
      <div class="img-layout-box" @click="goToUrl(6)">
        <img src="~@/assets/home/members_icon@2x.png" class="img-size" />
        <span class="lable-text">会员折扣</span>
      </div>
      <div class="img-layout-box" @click="goToUrl(5)">
        <img src="~@/assets/home/stock_zone@2x.png" class="img-size" />
        <span class="lable-text">闲置专区</span>
      </div>
      <div class="img-layout-box" @click="goToUrl('sign')">
        <img src="~@/assets/home/sign-in.png" class="img-size" />
        <span class="lable-text">签到有礼</span>
      </div>
      <div class="img-layout-box" @click="goToUrl(4)">
        <img
          :src="levelId * 1 === 5 ? iconClass : iconGoods"
          class="img-size"
        />
        <span class="lable-text">
          {{ levelId * 1 === 5 ? "批发专区" : "全部分类" }}
        </span>
      </div>
    </div>
    <div class="external-box" ref="outside">
      <div class="interior-box" ref="interior"></div>
    </div>
  </div>
</template>

<script>
import { getMyShop } from "@/api/shop";
export default {
  data() {
    return {
      pointer: "red-color",
      redColor: "",
      redColorRight: "",
      levelId: 0,
      iconClass: require("@/assets/home/icon-wholesale-market-area.png"),
      iconGoods: require("@/assets/home/icon-all-ategories.png"),
      newDistance: "",
      oldDistance: "",
      scrollDistance: "",
      outsideWidth: "",
      tabBarWidth: "",
    };
  },
  mounted() {
    this.oldDistance = this.$refs.outer.scrollWidth;
    this.tabBarWidth = this.$refs.outer.offsetWidth;
    this.outsideWidth = this.$refs.outside.offsetWidth * 0.5;
    if (this.$store.state.token && this.$store.state.m_id) {
      this.getUser();
    }
  },
  methods: {
    getScroll(e) {
      this.scrollDistance = this.$refs.outer.scrollLeft;
      this.newDistance =
        this.scrollDistance / (this.oldDistance - this.tabBarWidth);
      if (this.newDistance > 1) {
        this.newDistance = 1;
      }
      this.$refs.interior.style.left = `${
        this.outsideWidth * this.newDistance
      }px`;
      // console.log(this.$refs.interior.style.left);
    },
    goToUrl(type) {
      //页面跳转
      switch (type) {
        case 1:
          this.$router.push({ name: "native-live" });
          break;
        case 2:
          this.$router.push({ name: "beans-shopping" });
          break;
        case 3:
          this.$router.push({ name: "gold-list" });
          break;
        case 4:
          this.getUserInfo();
          break;
        case 5:
          this.$router.push({ name: "used" });
          break;
        case 6:
          this.$router.push({ name: "discount-list" });
          break;
        case 7:
          this.$router.push({ name: "seize-goods" });
          break;
        case 8:
          this.$router.push({ name: "run-index" });
          break;
        case "sign":
          this.$router.push({ name: "sign" });
          break;
        default:
      }
    },
    async getUser() {
      let ret = await getMyShop();
      if (ret.code * 1 === 1) {
        if (ret.data.type * 1 === 5 && ret.data.status * 1 === 1) {
          this.levelId = 5;
        }
      }
    },
    async getUserInfo() {
      if (this.levelId * 1 === 5) {
        this.$router.push({ name: "stock-list" });
      } else {
        this.$router.push({ name: "classify" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.custom-indicator {
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 40px;
  height: 6px;
  background-color: #eaeaea;
  border-radius: 6px;
  transform: translateX(-50%);
}
.red-color {
  width: 20px;
  height: 6px;
  background-color: #ed301d;
  border-radius: 6px;
  transition: all 0.5s;
}
.red-color-right {
  margin-left: 20px;
  width: 20px;
  height: 6px;
  background-color: #ed301d;
  border-radius: 6px;
  transition: all 0.5s;
}
.img-swiper-box {
  display: flex;
  justify-content: space-around;
}
.img-swiper-box span {
  font-size: 14px;
  color: #000;
}
.img-size {
  flex-shrink: 0;
  width: 52px;
  height: auto;
  padding-bottom: 10px;
}
.my-swipe {
  margin-bottom: 20px;
}
.layout {
  position: relative;
  height: 151px;
  margin: 12px 13px 15px 13px;
  border-radius: 16px;
  background-color: #fff;
}
.tab-bar {
  display: flex;
  padding: 25px 8px;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .img-layout-box {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    width: 71px;
    margin-right: 27px;
    &:last-child {
      margin: 0;
    }
    .lable-text {
      font-size: 14px;
      color: #6f6f6f;
    }
  }
}
.external-box {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
  height: 6px;
  border-radius: 65px;
  background-color: #eaeaea;
  .interior-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 100%;
    border-radius: 65px;
    background-color: #ed301d;
  }
}
</style>
