<template>
  <div class="discounts">
    <div
      class="discounts-list"
      v-for="(item, index) in discountInfo"
      :key="index"
    >
      <img
        src="@/assets/discounts/expire.png"
        class="expire"
        v-if="item.is_receive === 1 && item.status !== 2"
      />
      <img
        src="@/assets/discounts/pulldown_icon.png"
        class="pulldown_icon"
        v-if="item.status === 2 && item.is_receive === 1"
      />
      <div class="discounts-number" v-if="item.num > 0">{{ item.num }}张</div>
      <div class="list-left">
        <div class="discounts-money">
          <Price
            :price="item.money"
            integerStyle="22"
            decimalStyle="13"
          ></Price>
        </div>
        <div class="is-use">
          {{ item.full_type === 1 ? `满${item.full_money}元可用` : "无门槛" }}
        </div>
      </div>
      <div class="list-right">
        <div class="discounts-name">
          {{ item.name }}
        </div>
        <div class="discounts-bottom">
          <!-- <div class="time">{{ item.start_time }}~{{ item.end_time }}</div> -->
          <div class="time">{{ item.time_str }}</div>
          <div>
            <div
              class="use-button"
              v-if="item.is_receive === 1 && item.status !== 2"
              @click="goToUse(item)"
            >
              立即使用
            </div>
            <div
              class="use-button-color"
              v-else-if="item.is_receive === 0"
              @click="getDiscounts(item, index)"
            >
              立即领取
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    discountInfo: {},
  },
  data() {
    return {};
  },
  methods: {
    getDiscounts(item, index) {
      this.$emit("getDiscounts", item, index);
    },
    goToUse(item) {
      this.$emit("closePopup");
      this.$router.push({
        name: "discounts",
        query: {
          couponId: item.coupon_id,
          grantId: item.grant_id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.discounts {
  .discounts-list {
    display: flex;
    position: relative;
    height: 91px;
    width: 100%;
    margin-bottom: 10px;
    background: url("../../../../assets/discounts/list-bg.png") no-repeat center /
      100% 100%;
    &:last-child {
      margin-bottom: 0;
    }
    .expire {
      position: absolute;
      top: 0;
      right: 0;
      width: 58px;
      height: auto;
    }
    .pulldown_icon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 68px;
      height: auto;
    }
    .discounts-number {
      position: absolute;
      width: 35px;
      height: 17px;
      text-align: center;
      line-height: 17px;
      color: #fff;
      background: linear-gradient(90deg, #ffd871 0%, #f69f1c 100%);
      border-radius: 2px;
    }
  }
  .list-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 98px;
    height: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: hidden;
    .discounts-money {
      display: flex;
      align-items: center;
      color: #ed301d;
      .money-sign {
        font-size: 13px;
      }
      .money {
        font-size: 22px;
      }
    }
    .is-use {
      color: #ed301d;
      font-size: 11px;
    }
  }
  .list-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 11px 8px 11px 8px;
    box-sizing: border-box;
    .discounts-name {
      width: 206px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      font-size: 15px;
    }
    .discounts-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .time {
        color: #999999;
        font-size: 10px;
      }
      .use-button-color {
        width: 71px;
        height: 29px;
        text-align: center;
        line-height: 29px;
        color: #a24b00;
        background: linear-gradient(270deg, #f5f0d0 0%, #f6c078 100%);
        border-radius: 29px;
      }
      .use-button {
        width: 71px;
        height: 29px;
        text-align: center;
        line-height: 29px;
        box-sizing: border-box;
        border: 1px solid #ed2b13;
        background: transparent;
        color: #ed2b13;
        border-radius: 29px;
        font-size: 13px;
      }
    }
  }
}
</style>