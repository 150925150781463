<template>
  <div class="layout">
    <van-tabs
      v-model="active"
      sticky
      offset-top="60"
      background="transparent"
      @click="onClick"
    >
      <van-tab
        v-for="(item, index) in titleList"
        :key="index"
        @click="setGoodsList(item.id)"
        :title-class="{ textColor: num === index }"
      >
        <template #title>
          {{ item.tag_name }}
        </template>
        <GoodsShowLable
          :tagId="item.id"
          :sendType="1"
          v-if="!isBlank"
          @showBlank="showBlank"
        ></GoodsShowLable>
        <div class="blank-page" v-else>
          <img
            src="~@/assets/common/no_evaluation_icon_is_available.png"
            class="blank-img"
          />
        </div>
      </van-tab>
      <van-tab
        title="猜你喜欢"
        @click="setGoodsList(0)"
        :title-class="{ textColor: num === titleList.length }"
      >
        <GoodsShowLable
          :tagId="0"
          :sendType="3"
          v-if="!isBlank"
          @showBlank="showBlank"
        ></GoodsShowLable>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import GoodsShowLable from "@/components/goods-show/goods-show-label.vue";
import { indexTag } from "@/api/goods.js";
export default {
  name: "label-nav",
  components: {
    GoodsShowLable,
  },
  props: {},
  data() {
    return {
      active: 1,
      num: 0,
      titleList: [],
      tagId: 0,
      isBlank: false,
    };
  },
  mounted() {
    this.getIndexTag();
  },
  methods: {
    showBlank(bool) {
      this.isBlank = bool;
    },
    onClick(num) {
      this.num = num;
    },
    async getIndexTag() {
      // 获取分类标签
      const res = await indexTag();
      this.titleList = res.data;
      // this.num = res.data[0].id;
      // this.active = res.data[0].id;
    },
    setGoodsList(tagId) {
      // 更新商品列表信息
      // this.num = tagId;
      if (this.tagId * 1 === tagId * 1) {
        return false;
      }
      this.tagId = tagId;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.textColor {
  font-weight: 600;
}
// .layout {
//   // padding: 10px 0px;
// }
/deep/ .van-tabs {
  .van-tab {
    .van-tab__text {
      font-size: 18px;
      color: #000;
    }
  }
  .van-tabs__line {
    width: 42px;
    height: 8px;
    background: url("~@/assets/home/next in line@2x.png") no-repeat center/100%;
  }
}
/deep/ .van-tabs__line {
  z-index: 0;
}
.color {
  background-color: rgb(133, 36, 36);
}
/deep/.van-sticky--fixed {
  border-top: 1px solid rgb(243, 243, 243);
  background-color: #fff;
  border-bottom: 1px solid rgb(243, 243, 243);
  height: 48px;
  top: 60px !important;
}

</style>
