<template>
  <div class="seckill-max-box" v-if="isShow">
    <van-row type="flex" justify="space-between" align="center">
      <van-col span="6">
        <img
          src="~@/assets/seckill/limited-time-seconds-kill.png"
          alt=""
          class="logo-img"
        />
      </van-col>
      <div class="look-all" @click="goToUrl()">
        <div class="txt">查看全部</div>
        <van-icon name="arrow" color="#AAAAAA" size="12" />
      </div>
    </van-row>
    <GoodsShow :goodsList="goodsList"></GoodsShow>
  </div>
</template>

<script>
import GoodsShow from "./components/goods-show/goods-show.vue";
import { getSeckillList } from "@/api/index";
export default {
  name: "seckill-time",
  components: {
    GoodsShow,
  },
  data() {
    return {
      goodsList: [],
      isShow: false,
    };
  },
  async mounted() {
    const res = await getSeckillList();
    if (res.data.list.length > 0) {
      this.isShow = true;
    }
    this.goodsList = res.data.list;
  },
  methods: {
    goToUrl() {
      this.$router.push({ name: "seckill-time" });
    },
  },
};
</script>

<style lang="less" scoped>
.seckill-max-box {
  margin: 0 12px 10px 12px;
  padding: 12px 0px 12px 12px;
  background-color: #fff;
  border-radius: 15px;
}
.logo-img {
  width: 85px;
  height: 17px;
}
.look-all {
  padding-right: 12px;
  display: flex;
  align-items: center;
  color: #aaa;
  font-size: 14px;
  .txt {
    font-size: 12px;
    margin-right: 6px;
  }
}
</style>
