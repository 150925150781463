<template>
  <div>
    <van-search
      @click="showSearch = true"
      v-model="value"
      :readonly="true"
      show-action
      shape="round"
      background="linear-gradient(to bottom, #FFFFFF, #F9F8F8, #f9f9f9)"
      :style="{
        background: backColor
          ? '#FFF'
          : 'linear-gradient(to bottom, #FFFFFF, #F9F8F8, #f9f9f9)',
      }"
      placeholder="请输入搜索关键词"
    >
      <template #action>
        <!-- <img src="~@/assets/home/scan-code.png" class="scan-code-img" /> -->
      </template>
      <template #left>
        <img src="~@/assets/home/zhw-icon.png" class="logo-img" />
      </template>
    </van-search>
    <div class="home-max-box">
      <!-- 轮播图 -->
      <HomeSwiper></HomeSwiper>
      <!-- 分类导航 -->
      <TabNav></TabNav>
      <!-- 新人专享 -->
      <NewUser ref="refNew"></NewUser>
      <!-- 限时秒杀 -->
      <SeckillTime></SeckillTime>
      <Tmall></Tmall>
      <JingDong></JingDong>
      <!-- 标签导航 -->
      <LableNav></LableNav>
      <!-- 备案-->
      <div class="register-number" v-show="registerShow">
        渝ICP备17009953号-1
      </div>
    </div>
    <SearchPage
      :showSearch="showSearch"
      @setSearchStr="setSearchStr"
      @retreat="retreat"
    ></SearchPage>
    <DiscountsPopup ref="popup"></DiscountsPopup>
  </div>
</template>

<script>
import HomeSwiper from "@/pages/home/home/moudles/home-swiper/home-swiper";
import TabNav from "@/pages/home/home/moudles/tabnav/tabnav.vue";
import JingDong from "@/pages/home/home/moudles/Jingdong/Jingdong.vue";
import Tmall from "@/pages/home/home/moudles/Tmall/Tmall.vue";
import SeckillTime from "./moudles/seckill-time/seckill-time.vue";
import LableNav from "@/pages/home/home/moudles/label-nav/label-nav.vue";
import SearchPage from "@/pages/goods/goods-list/moudles/search-page";
import DiscountsPopup from "@/pages/discounts/components/home-popup/home-popup";
import NewUser from "./moudles/new-user/new-user.vue";
export default {
  name: "home",
  components: {
    HomeSwiper,
    TabNav,
    SeckillTime,
    LableNav,
    SearchPage,
    JingDong,
    Tmall,
    DiscountsPopup,
    NewUser,
  },
  data() {
    return {
      value: "",
      showSearch: false,
      backColor: false,
      registerShow: true,
    };
  },
  mounted() {
    if (this.$store.state.token && this.$store.state.m_id) {
      // this.$refs.popup.getHomeDiscounts();
      // this.$refs.refNew.getNewUserGoods();
    }
    addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    setSearchStr(searchStr) {
      this.setShowSearch(false);
      this.goToUrl(2, searchStr);
    },
    // 页面跳转
    goToUrl(type, data) {
      switch (type * 1) {
        case 1:
          this.$router.push({ name: "goods", query: { goodsId: data } });
          break;
        case 2:
          this.$router.push({
            name: "search-page",
            query: { searchStr: data },
          });
          break;
        default:
          break;
      }
    },
    retreat() {
      this.showSearch = false;
    },
    handleScroll() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 10) {
        this.registerShow = false;
      } else {
        this.registerShow = true;
      }
      if (scrollTop >= 550) {
        this.backColor = true;
      } else if (scrollTop < 550) {
        this.backColor = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-search {
  padding: 10px 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.register-number {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 59px;
  height: 52px;
  font-size: 13px;
  z-index: 10;
  color: #666666;
  text-align: center;
  line-height: 52px;
  border-top: 1px solid rgb(235, 235, 235);
  background: rgb(255, 255, 255);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
/deep/ .van-search__action {
  display: flex;
  align-items: center;
}
/deep/ .van-search__action {
  padding: 0;
}
/deep/ .van-search__content {
  border: 1px solid #fa0336;
  background-color: transparent;
}
/deep/ .van-cell {
  height: 37px;
  align-items: center;
}
.scan-code-img {
  padding-left: 14px;
  width: 30px;
}
.logo-img {
  margin-right: 14px;
  width: 62px;
  height: 42px;
}
.home-max-box {
  padding: 68px 0 70px 0;
}
.van-search__action:active {
  background-color: transparent;
}
.back-color {
  background-color: #fff !important;
}
</style>
