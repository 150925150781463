<template>
  <div class="new-user" v-if="isShow">
    <div class="head-box">
      <img src="@/assets/home/bg@2x.png" class="title-img" />
      <div class="more" @click="goToList">
        <span class="more-txt">查看更多</span>
        <van-icon name="arrow" color="#fff" size="12" />
      </div>
    </div>
    <div class="unordered-list">
      <div
        class="list"
        v-for="item in list"
        :key="item.id"
        @click="goToDetail(item)"
      >
        <div class="goods-img-box">
          <img :src="item.cover_img" class="goods-img" />
          <div class="new-user-tag">新人价</div>
        </div>
        <Price :price="item.price"></Price>
        <Price
          class="original-price"
          :price="item.spec_pricing"
          decimalStyle="12"
          spanColor="#b9babe"
          integerStyle="12"
        ></Price>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewUserGoods } from "@/api/NewUser.js";
export default {
  data() {
    return {
      list: [],
      isShow: false,
    };
  },
  mounted() {
    // if (this.$store.state.token && this.$store.state.m_id) {
    //   this.getNewUserGoods();
    // }
  },
  methods: {
    goToList() {
      this.$router.push({ name: "new-user" });
    },
    goToDetail(item) {
      this.$router.push({
        name: "detail",
        query: {
          goodsId: item.id,
          type: 5,
        },
      });
    },
    async getNewUserGoods() {
      const res = await getNewUserGoods();
      if (res.code === 1) {
        this.list = res.data.list;
        if (this.list.length > 0) {
          this.isShow = true;
        }
      } else {
        this.isShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.new-user {
  display: flex;
  flex-direction: column;
  height: 204px;
  margin: 0 12px 10px 12px;
  padding: 12px;
  background: url("../../../../../assets/home/bg-new@2x.png") no-repeat
    center/100% 100%;
  border-radius: 13px;
  box-sizing: border-box;
}
.head-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-img {
    width: 86px;
    height: 23px;
  }
  .more {
    display: flex;
    align-items: center;
    color: #fff;
    .more-txt {
      margin-right: 6px;
    }
  }
}
.unordered-list {
  display: flex;
  height: 100%;
  margin-top: 12px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .list {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93px;
    background-color: #fff;
    padding-top: 8px;
    border-radius: 6px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  .goods-img-box {
    position: relative;
    width: 78px;
    height: 78px;
    margin-bottom: 12px;
    .goods-img {
      width: 100%;
      height: 100%;
      background-color: rgb(238, 238, 238);
      border-radius: 6px;
    }
    .new-user-tag {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -8px;
      width: 54px;
      height: 19px;
      font-size: 10px;
      color: #fff;
      background: linear-gradient(181deg, #f26e0e 0%, #ff3a1c 100%);
      border-radius: 19px;
      text-align: center;
      line-height: 19px;
    }
  }
  .original-price {
    font-size: 12px;
    color: #b9babe;
    text-decoration: line-through;
  }
}
</style>