<template>
  <van-popup v-model="popupShow">
    <div class="popup-box">
      <div class="popup-layout">
        <div class="popup-content" v-if="isNewUser === 0">
          <div class="title-img-box">
            <img src="@/assets/discounts/title-img.png" class="title-img" />
          </div>
          <HomeList
            :discountInfo="discountInfo"
            @getDiscounts="getDiscounts"
          ></HomeList>
          <div class="bottom-btn">
            <div
              class="bottom-btn"
              v-if="isAll"
              @click="getDiscounts(null, -1)"
            >
              全部领取
            </div>
            <div class="bottom-btn" v-else @click="lookMyDiscount">
              查看我的优惠券
            </div>
          </div>
        </div>
        <div class="new-popup-content" v-else>
          <div class="title-img-box">
            <img src="@/assets/discounts/new-title-img.png" class="title-img" />
          </div>
          <HomeList
            :discountInfo="discountInfo"
            @getDiscounts="getDiscounts"
          ></HomeList>
          <div class="new-bottom-btn">
            <div
              class="bottom-btn"
              v-if="isAll"
              @click="getDiscounts(null, -1)"
            >
              全部领取
            </div>
            <div class="bottom-btn" v-else @click="lookMyDiscount">
              查看我的优惠券
            </div>
          </div>
        </div>
        <div class="close-icon-box">
          <van-icon
            name="close"
            color="#fff"
            size="40"
            class="close-icon"
            @click="closePopup"
          />
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import HomeList from "../home-list/home-list";
import { getHomeDiscounts, getDiscounts } from "@/api/discounts.js";
import { Toast } from "vant";
import { intercept } from "@/common/js/utils.js";
export default {
  components: {
    HomeList,
  },
  props: {},
  data() {
    return {
      discountInfo: [],
      popupShow: false,
      isNewUser: 0,
      isAll: false,
      discount: {
        coupon_id: null,
        type: 2,
        grant_id: null,
      },
    };
  },
  mounted() {},
  methods: {
    lookMyDiscount() {
      this.closePopup();
      this.$router.push({ name: "my-discounts" });
    },
    async getDiscounts(item, index) {
      if (index >= 0) {
        this.discount.type = 1;
        this.discount.coupon_id = item.coupon_id;
        this.discount.grant_id = item.grant_id;
      } else {
        this.discount.type = 2;
      }
      const res = await getDiscounts(this.discount);
      if (res.code === 1) {
        if (index >= 0) {
          this.discountInfo[index].is_receive = 1;
        } else {
          this.getAll();
        }
        this.validator();
        Toast.success(res.msg);
      }
    },
    async getHomeDiscounts() {
      const res = await getHomeDiscounts();
      if (res.code === 1) {
        this.isNewUser = res.data.is_new;
        if (res.data.list.length > 0) {
          intercept(res.data.list);
          this.discountInfo = res.data.list;
          let index = this.discountInfo.findIndex((item) => {
            return item.is_receive === 0;
          });
          if (index === -1) {
            this.popupShow = false;
          } else {
            this.popupShow = true;
          }
          this.validator();
        } else {
          this.popupShow = false;
        }
      }
    },
    openPopup() {
      this.getHomeDiscounts();
    },
    getAll() {
      this.discountInfo.forEach((item) => {
        if (item.is_receive === 0) {
          item.is_receive = 1;
        }
      });
    },
    validator() {
      let index = null;
      index = this.discountInfo.findIndex((item) => item.is_receive === 0);
      if (index === -1) {
        this.isAll = false;
      } else {
        this.isAll = true;
      }
    },
    closePopup() {
      this.popupShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background-color: transparent;
}
.close-icon-box {
  margin-top: 22px;
  display: flex;
  justify-content: center;
}
.new-popup-content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 534px;
  width: 100vw;
  padding: 0 33px;
  padding-top: 14px;
  box-sizing: border-box;
  background: url("../../../../assets/discounts/home-bg-img.png") no-repeat
    center / 100% 100%;
  .title-img-box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 57px;
    .title-img {
      width: 199px;
      height: auto;
    }
  }
}
.new-bottom-btn {
  display: flex;
  justify-content: center;
  height: 136px;
  .bottom-btn {
    margin-top: 30px;
    width: 297px;
    height: 49px;
    background: linear-gradient(360deg, #ffc890 0%, #fff8d0 100%);
    box-shadow: 0px 4px 0px #e0a058;
    border-radius: 49px;
    line-height: 49px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #b01606;
  }
}
.popup-content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 478px;
  width: 366px;
  background: url("../../../../assets/discounts/my-qr-code.png") no-repeat
    center / 100% 100%;
  .title-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 93px;
    .title-img {
      width: 148px;
      height: 70px;
    }
  }
}
.discounts {
  flex: 1;
  padding: 0 10px;
  margin-top: 10px;
  overflow: auto;
  .discounts-list {
    display: flex;
    position: relative;
    height: 91px;
    width: 100%;
    margin-bottom: 12px;
    background: url("../../../../assets/discounts/list-bg.png") no-repeat center /
      100% 100%;
    &:last-child {
      margin-bottom: 0;
    }
    .discounts-number {
      position: absolute;
      width: 35px;
      height: 17px;
      text-align: center;
      line-height: 17px;
      color: #fff;
      background: linear-gradient(90deg, #ffd871 0%, #f69f1c 100%);
      border-radius: 2px;
    }
  }
  .list-left {
    width: 91px;
    height: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: hidden;
    .discounts-money {
      display: flex;
      align-items: center;
      padding-top: 19px;
      color: #ed301d;
      .money-sign {
        font-size: 13px;
      }
      .money {
        font-size: 22px;
      }
    }
    .is-use {
      color: #ed301d;
      font-size: 11px;
    }
  }
  .list-right {
    padding: 11px 13px;
    box-sizing: border-box;
    .discounts-name {
      width: 206px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 17px;
    }
    .discounts-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 17px;
      .time {
        color: #999999;
        font-size: 11px;
      }
      .use-button-color {
        width: 71px;
        height: 29px;
        text-align: center;
        line-height: 29px;
        color: #a24b00;
        background: linear-gradient(270deg, #f5f0d0 0%, #f6c078 100%);
        border-radius: 29px;
      }
      .use-button {
        width: 71px;
        height: 29px;
        text-align: center;
        line-height: 29px;
        box-sizing: border-box;
        border: 1px solid #ed2b13;
        background: transparent;
        color: #ed2b13;
        border-radius: 29px;
      }
    }
  }
}
.bottom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  .bottom-btn {
    width: 297px;
    height: 40px;
    background: linear-gradient(360deg, #fdbf50 0%, #fff480 100%);
    box-shadow: 0px 2px 0px #dc961d;
    line-height: 40px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #b01606;
    opacity: 1;
    border-radius: 40px;
  }
}
</style>
