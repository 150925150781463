<template>
  <van-overlay :show="showSearch" duration="0">
    <!-- 搜索 -->
    <Search @setSelectStr="setSelectStr">
      <div slot="left" class="search-left">
        <van-icon name="arrow-left" size="24" color="#6F6F6F" @click="$emit('retreat')" />
      </div>
      <div slot="right" class="search-right">
        <van-button
          round
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="onSearch"
        >
          搜索
        </van-button>
      </div>
    </Search>
    <!-- 关键字 -->
    <div class="keyword-operation">
      <!-- 热门 -->
      <div class="hot-search">
        <div class="hot-title-text">热门搜索</div>
        <div class="content">
          <span
            class="keyword"
            v-for="(hot_str, h_index) in hotSearch"
            @click="setSearchStr(hot_str)"
            :key="h_index"
          >
            {{ hot_str }}
          </span>
        </div>
      </div>
      <!-- 历史 -->
      <div class="history-search">
        <div class="history-title-text">
          <div class="title">历史搜索</div>
          <van-icon name="delete-o" @click="onDel" size="18" />
        </div>
        <div class="content">
          <span
            class="keyword"
            v-for="(my_str, m_index) in mySearch"
            @click="setSearchStr(my_str)"
            :key="m_index"
          >
            {{ my_str }}
          </span>
        </div>
      </div>
      <PromptPopup :msg="msg" :showPopup="showPopup" @popupFn="popupFn"></PromptPopup>
    </div>
  </van-overlay>
</template>

<script>
import Search from "@/components/search/search";
import PromptPopup from "@/components/prompt-popup/prompt-popup";
import { delSearch, getSearch } from "@/api/goods";
import { Toast } from "vant";

export default {
  name: "",
  components: {
    Search,
    PromptPopup,
  },
  props: {
    showSearch: {
      type: Boolean,
    },
  },
  data() {
    return {
      msg:'是否确认删除全部历史？',
      showPopup: false,
      active: 1,
      hotSearch: [],
      mySearch: [],
      select_str: "",
    };
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    // async delStr() {
    //   const ret = await delSearch();
    //   if (ret.code * 1 == 1) {
    //     this.mySearch.splice(0, this.mySearch.length);
    //     this.onDel();
    //   }
    // },
    onSearch() {
      this.$router.push({
        path: "/search-goods",
        query: { searchStr: this.select_str },
      });
    },
    onDel() {
      this.showPopup = true;
    },
    async popupFn(decide) {
      if (decide) {
        const res = await delSearch();
        if (res.code === 1) {
          this.getSearch();
          Toast.success("删除成功");
        }
      }
      this.showPopup = false;
    },
    setSearchStr(str) {
      // 点击历史去到页面
      if (str === "") {
        Toast.fail("请输入内容");
        return false;
      }
      this.$router.push({
        path: "/search-goods",
        query: { searchStr: str },
      });
      // this.$router.push({ path: "/goods-list", query: { strs: str } });
    },
    setSelectStr(str) {
      this.select_str = str;
    },
    async getSearch() {
      const ret = await getSearch();
      if (ret.code * 1 == 1) {
        this.mySearch = ret.data.my_search;
        this.hotSearch = ret.data.hot_search;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
}
.van-overlay {
  // 遮罩层
  background-color: #fff;
  z-index: 1000;
}
.keyword-operation {
  background-color: #fff;
  padding: 12px;
}

.hot-search {
  .hot-title-text {
    font-size: 18px;
    font-weight: 600;
  }
}

.history-search {
  .history-title-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding-top: 24px;
    .title {
      font-size: 18px;
    }
  }
}

.content {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 10px;

  .keyword {
    font-size: 14px;
    padding: 6px 16px;
    border-radius: 16px;
    background-color: #f8f8f8;
    margin: 10px 12px 0 0;
  }
}
.van-popup {
  border-radius: 16px;
}
</style>
