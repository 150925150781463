<template>
  <div
    class="Jingdong-moudles"
    v-show="list.length > 0 && platformType * 1 !== 2"
  >
    <img src="@/assets/goods/bg2@2x.png" class="back-img" />
    <div class="card-title">
      <img src="@/assets/goods/jingdong-logo@2x.png" class="logo-img" />
      <div class="right" @click="goToUrl">
        <div class="right-text">查看全部</div>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="goods-list">
      <div class="goods-card" v-for="(item, index) in list" :key="index">
        <a :href="item.goods_link">
          <img :src="item.goods_img" class="goods-img" />
          <div class="goods-info">
            <div class="goods-name">
              {{ item.goods_title }}
            </div>
            <div class="price">
              <span class="price-common">￥</span>
              <span class="integer">{{ toInteger(item.zk_price) }}</span>
              <span class="price-common">{{ twoFloating(item.zk_price) }}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/common/js/tools";
import { getJdGoodsList } from "@/api/platform";

export default {
  data() {
    return {
      list: [],
      platformType: 0,
    };
  },
  mounted() {
    this.platformType = tools.platformType();
    this.getJdGoodsList();
  },
  methods: {
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
    },
    goToUrl() {
      this.$router.push({ name: "Jingdong" });
    },
    async getJdGoodsList() {
      let ret = await getJdGoodsList();
      if (ret.code * 1 === 1) {
        this.list = ret.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Jingdong-moudles {
  position: relative;
  background-color: #fff;
  border-radius: 13px;
  margin: 0 12px 10px 12px;
  padding: 0 0 16px 12px;
  overflow: hidden;
  .back-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 91px;
  }
}
.card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  padding-right: 7px;
  .logo-img {
    width: 88px;
    height: auto;
    z-index: 1;
  }
  .right {
    display: flex;
    align-items: center;
    color: #9c9c9c;
    font-size: 12px;
    z-index: 1;
    .right-text {
      margin-right: 6px;
      font-size: 12px;
    }
  }
}
.goods-list {
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .goods-card {
    z-index: 1;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    .goods-img {
      width: 98px;
      height: 98px;
      border-radius: 6px 6px 0 0;
    }
    .goods-info {
      padding: 6px 3px 0 3px;
      .goods-name {
        font-size: 14px;
        color: #000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}
.price {
  color: #f2433a;
  .price-common {
    font-size: 12px;
  }
  .integer {
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
