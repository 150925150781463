<template>
  <div class="layout">
    <div
      class="layout-max-box"
      @click="
        goToUrl(item.id, item.goods_id, item.sales, item.stock, item.is_remind)
      "
      v-for="(item, index) in goodsList"
      :key="item.id"
    >
      <div>
        <img :src="item.cover_img" class="goods-img" />
      </div>
      <!-- 详情操作 -->
      <div class="particulars-info">
        <div class="title">{{ item.good_name }}</div>
        <div class="operation">
          <div class="price">
            <div class="now-price">
              <span>￥</span>
              <span class="integer">{{ toInteger(item.price) }}</span>
              <span>{{ twoFloating(item.price) }}</span>
            </div>
            <div class="former-price">
              <span>￥</span>
              <span>{{ toInteger(item.original_price) }}</span>
              <span>{{ twoFloating(item.original_price) }}</span>
            </div>
          </div>
          <div class="purchase-button-no" v-if="item.sales >= item.stock">
            已售罄
          </div>
          <div class="operation-button" v-else-if="timer >= item.hour_time">
            立即抢购
          </div>
          <div
            class="purchase-button"
            @click="reserveBtn(item.id, index)"
            @click.stop
            v-else-if="item.is_remind === 0"
          >
            预约提醒
          </div>
          <div class="purchase-button-ok" v-else>预约成功</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setRemind } from "@/api/seckill";
import { Toast } from "vant";
import tools from "@/common/js/tools";

export default {
  name: "goods-show",
  props: {
    goodsList: {
      type: Array,
      default: () => {
        return [1, 2, 3];
      },
    },
  },
  data() {
    return {
      buttonShow: 2, // 根据不同的状态码判断显示那个按钮
      timer: "",
    };
  },
  mounted() {
    // console.log(this.goodsList)
    this.timer = Math.trunc(Date.now() * 0.001);
  },
  methods: {
    async reserveBtn(id, index) {
      const res = await setRemind({ seckill_id: id });
      if (res.code * 1 === 1) {
        Toast.success("预约成功");
        // this.$emit("touchClick");
        this.goodsList[index].is_remind = 1;
      }
    },
    goToUrl(seckillId, goodsId, sales, stock, isRemind) {
      this.$router.push({
        name: "seckill-details",
        query: {
          seckillId: seckillId,
          goodsId: goodsId,
          type: 4,
          sales,
          stock,
          isRemind,
        },
      });
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = Math.floor(num * 100) / 100;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      //
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  margin-top: 14px;
  display: flex;
  justify-content: start;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.layout-max-box {
  flex-shrink: 0;
  width: 146px;
  height: 232px;
  margin-right: 12px;
  border-radius: 10px;
  overflow: hidden;
}
.goods-img {
  display: block;
  width: 100%;
  height: 146px;
}
.particulars-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px;
}
.particulars-info .title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.operation {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.now-price {
  color: #ed301d;
  font-size: 14px;
  .integer {
    font-weight: 500;
    font-size: 18px;
  }
}
.former-price {
  color: #B9BABE;
  font-size: 12px;
  text-decoration: line-through;
}
.operation-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 26px;
  margin-top: 6px;
  background: linear-gradient(to right, #fc5656, #f70606);
  border-radius: 26px;
  color: #fff;
  font-size: 12px;
  box-shadow: 0px 0px 4px #ffe1e1;
}
.purchase-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 26px;
  margin-top: 6px;
  background: linear-gradient(to right, #fca83a, #fe9001);
  border-radius: 26px;
  color: #fff;
  font-size: 12px;
  box-shadow: 0px 2px 4px #ffe1e1;
}
.purchase-button-ok {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 26px;
  margin-top: 6px;
  border-radius: 26px;
  color: #fff;
  font-size: 12px;
  border: 1px solid #ee9b3c;
  color: #ee9b3c;
  box-shadow: 0px 2px 4px #ffe1e1;
}
.purchase-button-no {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 26px;
  margin-top: 6px;
  background-color: #aaa;
  border-radius: 26px;
  color: #fff;
  font-size: 12px;
  box-shadow: 0px 2px 4px #ffe1e1;
}
</style>
