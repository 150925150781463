<template>
  <div class="swipe-box">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#ED301D">
      <van-swipe-item
        v-for="(item, index) in banner"
        :key="index"
        @click="goToDetails(item)"
      >
        <img :src="item.image" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { newsBanner } from "@/api/news.js";
export default {
  name: "home-swiper",
  data() {
    return {
      banner: [],
    };
  },
  mounted() {
    this.getBanner();
  },
  updated() {},
  methods: {
    goToDetails(item) {
      console.log(item);
      if (item.type * 1 === 1) {
        this.$router.push({
          name: "goods",
          query: {
            goodsId: item.to_id,
            type: item.type,
          },
        });
      } else if (item.type * 1 === 2) {
        this.$router.push({ name: "classify" });
      } else if (item.type * 1 === 3) {
        this.$router.push({ name: "seckill-time" });
      } else if (item.type * 1 === 4 && item.link !== "") {
        window.location.href =item.link
      }
    },
    async getBanner() {
      const res = await newsBanner({ a: "aaaa" });
      // console.log("轮播---------", res);
      this.banner = res.data;
    },
    goToUrl(item) {
      switch (item.type * 1) {
        case 1:
          // 跳转商品
          this.$router.push("/home");
          break;
        case 2:
          // 跳转分类
          this.$router.push("/home");
          break;
        case 3:
          // 跳转秒杀
          this.$router.push("/home");
          break;
        case 4:
          // 跳转外部链接
          this.$router.push("/home");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.swipe-box {
  padding: 0 12px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 0;
  border-radius: 10px;
  overflow: hidden;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: auto;
}
/deep/.van-swipe__indicator {
  background-color: #ffffff;
  opacity: 0.8;
  border-radius: 50%;
  border: 1px solid #fff;
}
</style>
